/* .App {
  text-align: center;
  background-color: #282c34;
  color: white;
} */



.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: left;
  font-size: 20px;
  color: white;
}

h1 {
  font-size: 3em;
}

h3 {
  font-size: 1.5em;
}

h4 {
  font-size: 1em;
}

input[type=button] {
  color: white;
  height: 5vh;
  width: 5vh;
  font-size: 2vmin;
  margin: 3px;
  border: 0;
  background: none;
  box-shadow: none;
  border-radius: 2px;
}

input[type=text] {
  text-transform: lowercase;
}

.nobr {
  white-space: nowrap;
}

.rec {
  background-color: #1a1c21;
  border-radius: 1em;
  margin-bottom: 20px;
  width: 25em;
}

.notification {
  background-color: green;
  width: 25em;
  padding-block: 10px;
  margin-top: 1em;
  border-radius: 0.5em;
}

.error {
  background-color: red;
  width: 25em;
  padding-block: 10px;
  margin-top: 1em;
  border-radius: 0.5em;
}